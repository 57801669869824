export default {
  basicViewItem: [{
    prop: 'id',
    label: '',
    Object: 'value'
  }, {
    prop: 'host_name',
    label: '',
    Object: 'value'
  }, {
    prop: 'full_address',
    label: '',
    Object: 'value'
  }, {
    prop: 'application_type',
    label: '',
    Object: 'value'
  }, {
    prop: 'effective_date',
    label: '',
    Object: 'value'
  }, {
    prop: 'remarks',
    label: '',
    Object: 'value',
    span: 24
  }, {
    prop: 'application_status',
    label: '',
    Object: 'value'
  }]
}
